import { ApiBase } from '@/api/apis/ApiBase';
import { ContentType, Header, HttpVerb } from '@/api/types/enum/Http';
import { FileReq } from '@/api/types/model/FileReq';

export class RegulationDocumentApi extends ApiBase {
  setRouteGroup (): string {
    return 'RegulationDocuments';
  }

  public GetLatestDocument () {
    return this.doReq<string>({
      path: '',
      method: HttpVerb.GET,
    });
  }

  public Create (file: FileReq) {
    const formData = new FormData();

    formData.append('File', file.data as Blob, file.fileName || 'File');

    return this.doReq<boolean>({
      path: '',
      method: HttpVerb.POST,
      data: formData,
      headers: {
        [Header.contentType]: ContentType.FORMDATA,
      },
    });
  }
}
