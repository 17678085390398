import { ApiBase } from '@/api/apis/ApiBase';
import { HttpVerb } from '@/api/types/enum/Http';
import { User, SetNewPassword, LoginType, CreateMobileUserModel } from '@/api/types/model/Auth';
import { PlaceholderParamObject } from '@/utils/string-placeholder';

export class AuthApi extends ApiBase {
  setRouteGroup (): string {
    return 'Auth';
  }

  public GetAccount (selectedCompanyId?: number) {
    const query: PlaceholderParamObject = {};
    if (selectedCompanyId) {
      query.selectedCompanyId = selectedCompanyId;
    }
    return this.doReq<User>({
      path: '',
      method: HttpVerb.GET,
      query,
    });
  }

  public ChangePassword (currentPassword: string, newPassword: string) {
    return this.doReq<User>({
      path: 'changePassword',
      method: HttpVerb.POST,
      data: {
        currentPassword,
        newPassword,
      },
    });
  }

  public Login (email: string, password: string, loginType: LoginType) {
    return this.doReq<User>({
      path: 'login',
      method: HttpVerb.POST,
      data: {
        email,
        password,
        loginType,
      },
    });
  }

  public LoginToken (token: string) {
    return this.doReq<User>({
      path: `login/${token}`,
      method: HttpVerb.GET,
    });
  }

  public RefreshToken (token: string, loginType: LoginType) {
    return this.doReq<User>({
      path: 'refresh',
      method: HttpVerb.POST,
      data: {
        token,
        loginType,
      },
    });
  }

  public RequestToken (phoneNumber: string, projectId: number) {
    return this.doEmptyReq({
      path: `requestToken/${phoneNumber}/${projectId}`,
      method: HttpVerb.GET,
    });
  };

  //@TODO
  public ResetPassword (emailAddress: string) {
    return this.doEmptyReq({
      path: 'resetPassword',
      method: HttpVerb.GET,
      query: {
        emailAddress,
      },
    });
  }

  public SetNewPassword (token: string, newPassword: string) {
    return this.doReq<SetNewPassword>({
      path: 'setNewPassword',
      method: HttpVerb.POST,
      data: {
        token,
        newPassword,
      },
    });
  }

  public CreateMobileUser (firstName: string, lastName: string, phoneNumber: string, projectId: number, companyId: number) {
    return this.doReq<CreateMobileUserModel>({
      path: 'createMobileUser',
      method: HttpVerb.POST,
      data: {
        firstName,
        lastName,
        phoneNumber,
        projectId,
        companyId,
      },
    });
  }
}
