import { CompliApi } from '@/api/CompliApi';
import { User } from '@/api/types/model/Auth';
import { useCookies } from '@/composables/storage/useCookies';
import { subdomainLoginType } from '@/utils/environment';
import { parseJWT } from '@/utils/jwt';
import { defineStore } from 'pinia';

export interface CompanyInfo {
  id: number;
  name: string;
};

interface State {
  user: User;
  selectedCompanyId: number;
  givenName: string;
  roles: string[];
  companyIds: number[];
  companyProjectIds: number[];
  controllingProjectId: number[];
  customerIds: number[];
  tokenRefreshTimeoutId?: number;
}

const dummyUser = { id: -1, emailConfirmed: false, phoneNumberConfirmed: false };

export const useAuthStore = defineStore('auth', {
  state: (): State => ({
    user: dummyUser,
    selectedCompanyId: 0,
    givenName: '',
    roles: [],
    companyIds: [],
    companyProjectIds: [],
    controllingProjectId: [],
    customerIds: [],
  }),
  actions: {
    reset () {
      const { clearTokens } = useCookies();
      clearTokens();
      this.user = dummyUser;
      this.selectedCompanyId = 0;
      this.givenName = '';
      this.roles = [];
      this.companyIds = [];
      this.companyProjectIds = [];
      this.controllingProjectId = [];
      this.customerIds = [];
      if (this.tokenRefreshTimeoutId) clearTimeout(this.tokenRefreshTimeoutId);
      this.tokenRefreshTimeoutId = undefined;
      this.$router.push('/login');
    },
    logout () {
      this.reset();
    },
    setUser (user: User) {
      this.user = user;
      const { getTokens, setTokens } = useCookies();
      const tokens = getTokens();
      const accessToken = user.accessToken || tokens.accessToken;
      const refreshToken = user.refreshToken || tokens.refreshToken;
      const expiresAt = user.expiresAt || tokens.expiresAt;
      setTokens(accessToken, refreshToken, expiresAt?.toString());
      const jwt = parseJWT(accessToken || '');
      this.selectedCompanyId = jwt.selectedCompanyId;
      this.givenName = jwt.name;
      this.roles = jwt.roles;
      this.companyIds = jwt.companyIds;
      this.companyProjectIds = jwt.companyProjectIds;
      this.controllingProjectId = jwt.controllingProjectId;
      this.customerIds = jwt.customerIds;
      this.setRefreshTimeout(jwt.exp);
    },
    async getUser (selectedCompanyId?: number) {
      const { getTokens } = useCookies();
      const { accessToken } = getTokens();
      if (!accessToken) {
        return;
      }
      const jwt = parseJWT(accessToken);
      if (new Date() >= jwt.exp) {
        return this.refreshToken();
      }
      try {
        const user = await CompliApi.Auth.GetAccount(selectedCompanyId);
        return this.setUser(user);
      } catch (e) {
        console.error(e);
      }
    },
    login (email: string, password: string) {
      return CompliApi.Auth.Login(email, password, subdomainLoginType()).then(async user => {
        return this.setUser(user);
      });
    },
    loginByToken (token: string) {
      return CompliApi.Auth.LoginToken(token).then(async user => {
        return this.setUser(user);
      });
    },
    changePassword (currentPassword: string, newPassword: string) {
      return CompliApi.Auth.ChangePassword(currentPassword, newPassword).then(user => {
        return this.setUser(user);
      });
    },
    async refreshToken () {
      const { getTokens } = useCookies();
      const { refreshToken, expiresAt } = getTokens();
      if (!refreshToken || !expiresAt || new Date() >= expiresAt) {
        return this.reset();
      }
      try {
        const user = await CompliApi.Auth.RefreshToken(refreshToken, subdomainLoginType());
        return this.setUser(user);
      } catch (e) {
        return this.reset();
      }
    },
    setRefreshTimeout (exp: Date) {
      const timeout = (exp.getTime() - new Date().getTime()) - (5 * 60 * 1000);
      this.tokenRefreshTimeoutId = setTimeout(async () => {
        this.refreshToken();
      }, timeout);
    },

  },
  getters: {
    isAuthenticated: (state): boolean => {
      return state.user.id > 0;
    },
    isAdmin: (state): boolean => {
      return state.user.id > 0 && state.roles.includes('Administrator');
    },
    isCompanyAdmin: (state): boolean => {
      return state.companyIds.length > 0;
    },
    isCustomerAdmin: (state): boolean => {
      return state.customerIds.length > 0;
    },
    isAdminOrCompanyAdmin: (state): boolean => {
      return (state.user.id > 0 && state.roles.includes('Administrator')) || state.companyIds.length > 0;
    },
    isAdminForCompany: (state) => {
      return (companyId: number): boolean => {
        return state.companyIds.includes(companyId);
      };
    },
    isAdminOrAdminForCompany () {
      return (companyId: number): boolean => {
        return this.isAdmin || this.isAdminForCompany(companyId);
      };
    },
    companies: (state): CompanyInfo[] => {
      const companies = state.user.companies || {};
      return Object.keys(companies).map((key) => {
        return { id: parseInt(key), name: companies[key] };
      });
    },
    selectedCompanyName: (state): string => {
      return state.user.companies?.[state.selectedCompanyId] || '';
    },
    getSelectedCompanyId: (state): number => {
      return state.selectedCompanyId;
    },
  },
});
