import { AnswerInputApi } from '@/api/apis/AnswerInput/AnswerInputApi';
import { AnswerApi } from '@/api/apis/Answer/AnswerApi';
import { AuditApi } from '@/api/apis/Audit/AuditApi';
import { AuthApi } from '@/api/apis/Auth/AuthApi';
import { CheckInApi } from '@/api/apis/CheckIn/CheckInApi';
import { CheckInLogApi } from '@/api/apis/CheckInLog/CheckInLogApi';
import { CompanyApi } from '@/api/apis/Company/CompanyApi';
import { CountryApi } from '@/api/apis/Country/CountryApi';
import { EmployeeApi } from '@/api/apis/Employee/EmployeeApi';
import { IdentityDocumentApi } from '@/api/apis/IdentityDocument/IdentityDocumentApi';
import { InviteApi } from '@/api/apis/Invite/InviteApi';
import { KVKApi } from '@/api/apis/KVK/KVKApi';
import { LanguageApi } from '@/api/apis/Language/LanguageApi';
import { LawApi } from '@/api/apis/Law/LawApi';
import { ProjectApi } from '@/api/apis/Project/ProjectApi';
import { QuestionnaireApi } from '@/api/apis/Questionnaire/QuestionnaireApi';
import { RecordApi } from '@/api/apis/Record/RecordApi';
import { Client, ClientConfiguration } from '@/api/client/Client';
import { VIESApi } from './apis/VIES/VIESApi';
import { QuestionApi } from './apis/Question/QuestionApi';
import { RecordTypeApi } from './apis/RecordType/RecordTypeApi';
import { RegulationDocumentApi } from './apis/RegulationDocument/RegulationDocumentApi';

const getAPIUrl = (): string => {
  return `${window.location.protocol}//${window.location.host}`;
};

class CompliApiBase {
  private static instance: CompliApiBase;
  private readonly apiClient: Client;
  Audit: AuditApi;
  Answer: AnswerApi;
  AnswerInput: AnswerInputApi;
  Auth: AuthApi;
  CheckIn: CheckInApi;
  CheckInLog: CheckInLogApi;
  Company: CompanyApi;
  Country: CountryApi;
  Employee: EmployeeApi;
  IdentityDocument: IdentityDocumentApi;
  Invite: InviteApi;
  KVK: KVKApi;
  Language: LanguageApi;
  Law: LawApi;
  Project: ProjectApi;
  Questionnaire: QuestionnaireApi;
  Record: RecordApi;
  VIES: VIESApi;
  Question: QuestionApi;
  RecordType: RecordTypeApi;
  RegulationDocument: RegulationDocumentApi;

  private constructor (config: ClientConfiguration) {
    this.apiClient = new Client(config);
    this.Audit = new AuditApi(this.apiClient);
    this.Answer = new AnswerApi(this.apiClient);
    this.AnswerInput = new AnswerInputApi(this.apiClient);
    this.Auth = new AuthApi(this.apiClient);
    this.CheckIn = new CheckInApi(this.apiClient);
    this.CheckInLog = new CheckInLogApi(this.apiClient);
    this.Company = new CompanyApi(this.apiClient);
    this.Country = new CountryApi(this.apiClient);
    this.Employee = new EmployeeApi(this.apiClient);
    this.Invite = new InviteApi(this.apiClient);
    this.IdentityDocument = new IdentityDocumentApi(this.apiClient);
    this.KVK = new KVKApi(this.apiClient);
    this.Language = new LanguageApi(this.apiClient);
    this.Law = new LawApi(this.apiClient);
    this.Project = new ProjectApi(this.apiClient);
    this.Questionnaire = new QuestionnaireApi(this.apiClient);
    this.Record = new RecordApi(this.apiClient);
    this.VIES = new VIESApi(this.apiClient);
    this.Question = new QuestionApi(this.apiClient);
    this.RecordType = new RecordTypeApi(this.apiClient);
    this.RegulationDocument = new RegulationDocumentApi(this.apiClient);
  }

  public static getInstance (): CompliApiBase {
    if (!CompliApiBase.instance) {
      CompliApiBase.instance = new CompliApiBase({
        url: getAPIUrl(),
        apiPrefix: 'api',
      });
    }
    return CompliApiBase.instance;
  }
}

const CompliApi = CompliApiBase.getInstance();
export { CompliApi };

